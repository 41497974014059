<template>
    <div class="jobs">
        <Spinner />
      <div class="container">
            <div class="main-title">
                <p>{{ this.$i18n.locale == 'ar' ? 'الوظائف' : 'Jobs' }}</p>
            </div>
            <div class="banner">
                <div class="row align-items-center">
                    <div class="col-md-6 text-content">
                        <h1 class="title">
                            {{ $t('JobsTitle') }}
                        </h1>
                        <p class="m-4">
                          {{ $t('JobsDescription') }}
                        </p>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center img-content">
                        <img src="@/assets/img/jobs/main-job.png" alt="UsingPolicy">
                    </div>
                </div>
            </div>

            <div class="row mb-5 info">
                <div class="container">

                    <ul class="nav nav-pills" id="jobs-tab" role="tablist">
                        <li class="nav-item active" role="presentation" v-for="item in JobsCategories" :key="item.id">
                            <button @click="getJobData(item.id)" data-index="job-1"
                                :class="['nav-link py-3', { 'active': item.id === 1 }]" id="v-pills-job-1-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-job-1" type="button" role="tab"
                                aria-controls="v-pills-job-1" aria-selected="true">
                                <span class=" fs-sm-4">{{ item.name }}</span>
                            </button>
                        </li>

                    </ul>

                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-job-1" role="tabpanel"
                            aria-labelledby="v-pills-job-1-tab">
                            <div class="row mt-5">

                                <div class="col-lg-3 col-md-4 col-sm-6" v-for="item in JobCategoryData" :key="item.id">
                                    <div class="item ">

                                        <div class="icon m-auto">
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.6 35.0525C2.61 35.0525 1.7628 34.7013 1.0584 33.9989C0.354 33.2964 0.0012 32.451 0 31.4625V11.7173C0 10.7301 0.3528 9.88524 1.0584 9.18279C1.764 8.48034 2.6112 8.12852 3.6 8.12732H10.8V4.53729C10.8 3.55004 11.1528 2.70518 11.8584 2.00273C12.564 1.30029 13.4112 0.948462 14.4 0.947266H21.6C22.59 0.947266 23.4378 1.29909 24.1434 2.00273C24.849 2.70638 25.2012 3.55123 25.2 4.53729V8.12732H32.4C33.39 8.12732 34.2378 8.47914 34.9434 9.18279C35.649 9.88643 36.0012 10.7313 36 11.7173V31.4625C36 32.4498 35.6478 33.2952 34.9434 33.9989C34.239 34.7025 33.3912 35.0537 32.4 35.0525H3.6ZM14.4 8.12732H21.6V4.53729H14.4V8.12732ZM32.4 24.2824H23.4V26.0775C23.4 26.586 23.2272 27.0127 22.8816 27.3573C22.536 27.7019 22.1088 27.8737 21.6 27.8725H14.4C13.89 27.8725 13.4628 27.7001 13.1184 27.3555C12.774 27.0109 12.6012 26.5848 12.6 26.0775V24.2824H3.6V31.4625H32.4V24.2824ZM16.2 24.2824H19.8V20.6924H16.2V24.2824ZM3.6 20.6924H12.6V18.8974C12.6 18.3888 12.7728 17.9628 13.1184 17.6194C13.464 17.2759 13.8912 17.1036 14.4 17.1024H21.6C22.11 17.1024 22.5378 17.2747 22.8834 17.6194C23.229 17.964 23.4012 18.39 23.4 18.8974V20.6924H32.4V11.7173H3.6V20.6924Z"
                                                    fill="#0a3041" />
                                            </svg>
                                        </div>

                                        <h2 class="title">{{ item.title }}</h2>
                                        <div class="job-data d-flex">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 9.20012C4 7.69132 4 6.93772 4.4688 6.46892C4.9376 6.00012 5.6912 6.00012 7.2 6.00012H16.8C18.3088 6.00012 19.0624 6.00012 19.5312 6.46892C20 6.93772 20 7.69132 20 9.20012C20 9.57692 20 9.76572 19.8832 9.88332C19.7656 10.0001 19.576 10.0001 19.2 10.0001H4.8C4.4232 10.0001 4.2344 10.0001 4.1168 9.88332C4 9.76572 4 9.57612 4 9.20012Z"
                                                    fill="#225476" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M4 16.4002C4 17.909 4 18.6626 4.4688 19.1314C4.9376 19.6002 5.6912 19.6002 7.2 19.6002H16.8C18.3088 19.6002 19.0624 19.6002 19.5312 19.1314C20 18.6626 20 17.909 20 16.4002V12.4002C20 12.0234 20 11.8346 19.8832 11.717C19.7656 11.6002 19.576 11.6002 19.2 11.6002H4.8C4.4232 11.6002 4.2344 11.6002 4.1168 11.717C4 11.8346 4 12.0242 4 12.4002V16.4002ZM8 14.0002C8 13.6234 8 13.4346 8.1168 13.317C8.2344 13.2002 8.424 13.2002 8.8 13.2002H10.4C10.7768 13.2002 10.9656 13.2002 11.0832 13.317C11.2 13.4346 11.2 13.6234 11.2 14.0002C11.2 14.377 11.2 14.5658 11.0832 14.6834C10.9656 14.8002 10.776 14.8002 10.4 14.8002H8.8C8.4232 14.8002 8.2344 14.8002 8.1168 14.6834C8 14.5658 8 14.3762 8 14.0002ZM8.1168 16.517C8 16.6346 8 16.8242 8 17.2002C8 17.5762 8 17.7658 8.1168 17.8834C8.2344 18.0002 8.424 18.0002 8.8 18.0002H10.4C10.7768 18.0002 10.9656 18.0002 11.0832 17.8834C11.2 17.7658 11.2 17.5762 11.2 17.2002C11.2 16.8242 11.2 16.6346 11.0832 16.517C10.9656 16.4002 10.776 16.4002 10.4 16.4002H8.8C8.4232 16.4002 8.2344 16.4002 8.1168 16.517ZM12.8 14.0002C12.8 13.6234 12.8 13.4346 12.9168 13.317C13.0344 13.2002 13.224 13.2002 13.6 13.2002H15.2C15.5768 13.2002 15.7656 13.2002 15.8832 13.317C16 13.4346 16 13.6234 16 14.0002C16 14.377 16 14.5658 15.8832 14.6834C15.7656 14.8002 15.576 14.8002 15.2 14.8002H13.6C13.2232 14.8002 13.0344 14.8002 12.9168 14.6834C12.8 14.5658 12.8 14.3762 12.8 14.0002ZM12.9168 16.517C12.8 16.6346 12.8 16.8242 12.8 17.2002C12.8 17.5762 12.8 17.7658 12.9168 17.8834C13.0344 18.0002 13.2232 18.0002 13.6 18.0002H15.2C15.5768 18.0002 15.7656 18.0002 15.8832 17.8834C16 17.7658 16 17.5762 16 17.2002C16 16.8242 16 16.6346 15.8832 16.517C15.7656 16.4002 15.576 16.4002 15.2 16.4002H13.6C13.2232 16.4002 13.0344 16.4002 12.9168 16.517Z"
                                                    fill="#225476" />
                                                <path d="M8 4.40002V6.80002M16 4.40002V6.80002" stroke="#225476"
                                                    stroke-width="2.5" stroke-linecap="round" />
                                            </svg>
                                            <p class="value">
                                                {{ this.$i18n.locale == 'ar' ?
                    `حد أقصى`
                    :
                    `Limit`
                                                }} :
                                            </p>
                                            <p class="key "> {{ item.deadline_to_apply }} </p>

                                        </div>
                                        <div class="job-data d-flex">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.8434 3.44536C11.9472 3.42528 12.0543 3.42528 12.1581 3.44536L18.8539 4.78452C19.2288 4.85817 19.5 5.18961 19.5 5.57127C19.5 5.95293 19.2288 6.28437 18.8539 6.35802L16.286 6.87359V8.78523C16.286 11.1522 14.3677 13.0705 12.0007 13.0705C9.63379 13.0705 7.71546 11.1522 7.71546 8.78523V6.87359L6.10847 6.5522V8.73167L6.63409 11.3564C6.66422 11.5138 6.62405 11.6778 6.52361 11.8017C6.42317 11.9255 6.26917 11.9992 6.10847 11.9992H5.03715C4.87645 11.9992 4.7258 11.9289 4.62201 11.8017C4.51823 11.6745 4.47806 11.5138 4.51153 11.3564L5.03715 8.73167V6.32789C4.7191 6.21741 4.50149 5.9161 4.50149 5.57127C4.50149 5.18961 4.77267 4.85817 5.14763 4.78452L11.8434 3.44536ZM8.24777 14.3996C8.5993 14.2858 8.97761 14.413 9.23205 14.6842L11.609 17.2119C11.82 17.4362 12.1782 17.4362 12.3891 17.2119L14.7661 14.6842C15.0205 14.413 15.3988 14.2858 15.7504 14.3996C17.9265 15.0993 19.5 17.1349 19.5 19.542C19.5 20.1111 19.038 20.5698 18.4722 20.5698H5.52929C4.9635 20.5698 4.50149 20.1078 4.50149 19.542C4.50149 17.1349 6.07499 15.0993 8.24777 14.3996Z"
                                                    fill="#225476" />
                                            </svg>


                                            <div class="d-flex">
                                                <p class="value">

                                                    {{ this.$i18n.locale == 'ar' ?
                    `المستوى الأكاديمي`
                    :
                    `Academic level`
                                                    }} :
                                                </p>
                                                <p class="key "> {{ item.academic_level }} </p>

                                            </div>
                                        </div>
                                        <div class="job-data d-flex">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.6 19.6C5.16 19.6 4.78347 19.4435 4.4704 19.1304C4.15733 18.8174 4.00053 18.4406 4 18V14.8H9.6V16.4H14.4V14.8H20V18C20 18.44 19.8435 18.8168 19.5304 19.1304C19.2173 19.444 18.8405 19.6006 18.4 19.6H5.6ZM11.2 14.8V13.2H12.8V14.8H11.2ZM4 13.2V9.20002C4 8.76002 4.1568 8.38349 4.4704 8.07042C4.784 7.75736 5.16053 7.60056 5.6 7.60002H8.8V6.00002C8.8 5.56002 8.9568 5.18349 9.2704 4.87042C9.584 4.55736 9.96053 4.40056 10.4 4.40002H13.6C14.04 4.40002 14.4168 4.55682 14.7304 4.87042C15.044 5.18402 15.2005 5.56056 15.2 6.00002V7.60002H18.4C18.84 7.60002 19.2168 7.75682 19.5304 8.07042C19.844 8.38402 20.0005 8.76056 20 9.20002V13.2H14.4V11.6H9.6V13.2H4ZM10.4 7.60002H13.6V6.00002H10.4V7.60002Z"
                                                    fill="#225476" />
                                            </svg>


                                            <div class="d-flex">
                                                <p class="value">

                                                    {{ this.$i18n.locale == 'ar' ?
                    `خبرة العمل`
                    :
                    `work experience`
                                                    }} :
                                                </p>
                                                <p class="key "> {{ item.required_experience }} {{ this.$i18n.locale ==
                    'ar' ?
                    `سنة`
                    :
                    `year`
                                                    }}</p>

                                            </div>
                                        </div>

                                        <router-link class="job-details" :to="'/jobs/details/' + item.id">

                                            {{ this.$i18n.locale == 'ar' ?
                    `عرض الوظيفة`
                    :
                    `show career`
                                            }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import Spinner from '../../components/Global/Spinner.vue';

export default {

    name: 'IndexJobs Page',
    components: {
        Spinner
    },
    data() {
        return {
            JobsCategories: [],
            JobCategoryData: []
        }
    },

    methods: {
        getJobData(id) {
            this.JobCategoryData = []
            this.$store.dispatch('JobCategoriesData', id).then(r => {
                this.JobCategoryData = this.$store.state.JobCategoryData;

            });
        },
    },

    mounted() {

        this.$store.dispatch('SETLOADER', true);
        const currentDate = new Date();
        this.$store.dispatch('GetJobCategories').then(r => {
            this.JobsCategories = this.$store.state.JobsCategories;

            this.$store.dispatch('JobCategoriesData', 1).then(r => {
                this.$store.state.JobCategoryData.forEach(element => {
                    if (new Date(element.deadline_to_apply) > currentDate) {
                        this.JobCategoryData.push(element)
                    }

                });

            });
        })

    }


}
</script>
